import { Box, Card, Typography } from "@mui/material";
import {
  type Audit,
  useAuditTags,
  useUpdateAudit,
} from "@trainwell/features/audits";
import { format } from "date-fns";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import { TagList } from "src/components/misc/TagList";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";

type Props = {
  audit: Audit;
};

export default function AuditEditPage({ audit }: Props) {
  const client = useAppSelector((state) =>
    selectClientById(state, audit.subject_user_id!),
  );
  const trainerNames = useAppSelector(selectTrainerNames);
  const realTrainer = useAppSelector((state) => state.trainer.trainer);
  const { data: auditTags } = useAuditTags({
    trainerId: realTrainer!.trainer_id,
  });
  const updateAudit = useUpdateAudit();

  return (
    <Box>
      <Typography variant="h1">
        Audit for {client?.full_name ?? "{name not found}"}
      </Typography>
      <Typography>
        Conducted by {getTrainerName(audit.auditor_trainer_id, trainerNames)}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.text.secondary, mb: 2 }}
      >
        Created {format(new Date(audit.date_created), "MMMM do, yyyy")}
      </Typography>
      <TagList
        value={audit.tags.map((t) => ({ id: t, label: t }))}
        options={auditTags?.map((t) => ({ id: t, label: t }))}
        onAdd={(tag) => {
          const newTags = [...audit.tags, tag];

          updateAudit.mutate({
            auditId: audit.id,
            data: {
              tags: newTags,
            },
          });
        }}
        onRemove={(tag) => {
          const newTags = [...audit.tags].filter((t) => t !== tag);

          updateAudit.mutate({
            auditId: audit.id,
            data: {
              tags: newTags,
            },
          });
        }}
        sx={{ mb: 2 }}
      />
      <Card variant="outlined" sx={{ p: 2 }}>
        <AutosaveTextField
          label="Audit"
          defaultValue={audit.text}
          key={audit.id}
          name="audit.text"
          disableGutter
          onSave={(text) => {
            updateAudit.mutate({
              auditId: audit.id,
              data: {
                text: text,
              },
            });
          }}
        />
      </Card>
    </Box>
  );
}
