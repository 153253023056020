import { Box, Typography, type SxProps, type Theme } from "@mui/material";
import { type MilestoneExercise } from "@trainwell/features/milestones";
import { exerciseLibrary } from "@trainwell/workout-lib";
import ExerciseStats from "src/features/workout-builder/ExerciseStats";
import SetHistoryButton from "src/features/workout-builder/SetHistoryButton";
import { useAppSelector } from "src/hooks/stateHooks";
import { getExerciseDisplayName } from "src/lib/mediaUtility";

type Props = {
  sx?: SxProps<Theme>;
  milestone: MilestoneExercise;
};

export function MilestoneCellExercise({ sx = [], milestone }: Props) {
  const preferredWeightSystem = useAppSelector(
    (state) => state.client.client?.preferred_weight_system ?? "imperial",
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Box sx={{ mr: 1 }}>
        <Typography>
          {milestone.text ||
            getExerciseDisplayName(milestone.content.exercise_master_id)}
        </Typography>
        {!milestone.date_achieved && (
          <ExerciseStats
            exerciseMasterId={milestone.content.exercise_master_id}
          />
        )}
      </Box>
      <SetHistoryButton
        exerciseName={getExerciseDisplayName(
          milestone.content.exercise_master_id,
        )}
        exerciseId={milestone.content.exercise_master_id}
        userId={milestone.user_id}
        weightSystem={preferredWeightSystem}
        exerciseSource={exerciseLibrary[milestone.content.exercise_master_id]}
      />
    </Box>
  );
}
