import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import DoNotDisturbRoundedIcon from "@mui/icons-material/DoNotDisturbRounded";
import EditNotificationsRoundedIcon from "@mui/icons-material/EditNotificationsRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import MarkChatUnreadRoundedIcon from "@mui/icons-material/MarkChatUnreadRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import SwitchLeftRoundedIcon from "@mui/icons-material/SwitchLeftRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import WavingHandRoundedIcon from "@mui/icons-material/WavingHandRounded";
import type { ActionItem } from "@trainwell/types";

type ActionItemDetails = {
  name: string;
  notes?: string;
  icon: JSX.Element;
  clearTypes?: ("clear_button" | "click")[];
};

export function getActionItemDetails(
  actionItem: ActionItem,
): ActionItemDetails {
  switch (actionItem.type) {
    case "workout_review":
      return {
        name: "Workout logged",
        notes: actionItem.workout_name,
        icon: (
          <FitnessCenterRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "progress_review":
      return {
        name: "Progress logged",
        notes: actionItem.notes,
        icon: (
          <ShowChartRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "inactive_user":
      return {
        name: "Inactive",
        notes: actionItem.notes,
        icon: (
          <HotelRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "client_returned_from_cancel":
      return {
        name: "Returned from cancel",
        notes: actionItem.notes,
        icon: (
          <EmojiPeopleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "client_returned_from_pause":
      return {
        name: "Returned from pause",
        notes: actionItem.notes,
        icon: (
          <EmojiPeopleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "custom":
      return {
        name: "Custom",
        notes: actionItem.notes,
        icon: (
          <EditNotificationsRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "switched_coach_to_you":
      return {
        name: "Switched to you",
        notes: actionItem.notes,
        icon: (
          <SwitchLeftRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
      };

    case "user_requested_cancelation":
      return {
        name: "Requested to cancel",
        notes: "Send a message to check-in",
        icon: (
          <RemoveCircleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
      };

    case "new_client":
      return {
        name: "Signed up",
        notes: "Send a welcome video",
        icon: (
          <EmojiPeopleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
      };

    case "read_chat":
      return {
        name: "Read chat",
        icon: (
          <MarkChatUnreadRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
      };

    case "respond":
      if (actionItem.respond_type === "question") {
        return {
          name: "Answer question",
          notes: actionItem.notes,
          icon: (
            <QuestionMarkRoundedIcon
              color={actionItem.date_finished ? "disabled" : undefined}
            />
          ),
        };
      } else {
        return {
          name: "Respond",
          notes: actionItem.notes,
          icon: (
            <ChatBubbleRoundedIcon
              color={actionItem.date_finished ? "disabled" : undefined}
            />
          ),
        };
      }

    case "1_day_unmessaged_weekday":
      return {
        name: "1 day unmessaged",
        notes: "Send a message",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "2_day_unmessaged":
      return {
        name: "2 day unmessaged",
        notes: "Send a message",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "3_day_unmessaged":
      return {
        name: "3 day unmessaged",
        notes: "Send a message",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "7_day_unmessaged":
      return {
        name: "7 day unmessaged",
        notes: "Send a message",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
      };

    case "follow_up_on_welcome_video":
      return {
        name: "Follow up",
        notes:
          "Client hasn't responded to your welcome video. Follow up with them.",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button", "click"],
      };

    case "missed_habit":
      return {
        name: "Missed habit",
        notes: `Missed ${actionItem.habit_task_name ?? "a habit"}`,
        icon: (
          <CropFreeRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "user_completed_first_habit":
      return {
        name: "Completed first habit",
        notes: `They completed ${actionItem.habit_task_name}`,
        icon: (
          <EmojiEventsRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "missed_workout_streak":
      // eslint-disable-next-line no-case-declarations
      const missedDays = actionItem.streak_length;

      return {
        name: "Missed workout streak",
        notes: `Missed ${missedDays} workout day${
          missedDays === 1 ? "" : "s"
        }. Send a message`,
        icon: (
          <DoNotDisturbRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "send_pre_check_in_survey":
      return {
        name: "Send pre-call survey",
        notes: actionItem.notes,
        icon: (
          <SendRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "client_unhidden":
      return {
        name: "Unhidden",
        notes: actionItem.notes,
        icon: (
          <VisibilityRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    case "check_in_survey_send":
      return {
        name: "Check-in",
        icon: (
          <SendRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "check_in_survey_reminder":
      return {
        name: "Remind about check-in survey",
        notes: "A survey hasn't been answered for 2+ days",
        icon: (
          <ChatBubbleRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["clear_button"],
      };

    case "check_in_survey_completed":
      return {
        name: "Check-in survey answered",
        icon: (
          <WavingHandRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };

    default:
      return {
        name: "Unknown",
        notes: "The dash doesn't know how to display this action item",
        icon: (
          <QuestionMarkRoundedIcon
            color={actionItem.date_finished ? "disabled" : undefined}
          />
        ),
        clearTypes: ["click"],
      };
  }
}
