import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useGoalTemplates } from "@trainwell/features/goals";
import { useProgressMetrics } from "@trainwell/features/progress-metrics";
import type { Client } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { updateClientLocal } from "src/slices/clientSlice";
import { updateClientInListLocal } from "src/slices/clientsSlice";
import GoalCardMetric from "./GoalCardMetric";
import GoalEditDialog from "./GoalEditDialog";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

type Props = {
  client: Pick<
    Client,
    "goal" | "user_id" | "preferred_weight_system" | "settings"
  >;
  disableEditing?: boolean;
  sx?: SxProps<Theme>;
};

export default function GoalCard({ client, disableEditing, sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const { data: goalTemplatesData } = useGoalTemplates();
  const goalTemplates = goalTemplatesData?.goal_templates ?? [];
  const { data } = useProgressMetrics();
  const progressMetrics = data?.progress_metrics ?? [];
  // const [showProgress, setShowProgress] = useState(false);
  const showProgress = true;
  const [isEditing, setIsEditing] = useState(false);
  const [goalTemplateId, setGoalTemplateId] = useState(
    client.goal?.goal_template_id ?? "",
  );
  const [nameCustom, setNameCustom] = useState(client.goal?.name_custom ?? "");
  const [trackedMetricsIds, setTrackedMetricsIds] = useState<string[]>(
    (client.goal?.progress_metric_summaries ?? []).map(
      (m) => m.progress_metric_id,
    ),
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  if (!client.goal) {
    return null;
  }

  function saveGoal() {
    if (client.goal?.goal_template_id !== goalTemplateId) {
      api.clients
        .addGoal({
          user_id: client.user_id,
          goal_template_id: goalTemplateId,
          name_custom: nameCustom,
          progress_metric_ids: trackedMetricsIds,
        })
        .then((res) => {
          dispatch(
            updateClientLocal({
              user_id: client.user_id,
              goal: res.goal,
            }),
          );
          dispatch(
            updateClientInListLocal({
              user_id: client.user_id,
              goal: res.goal,
            }),
          );
        });
    } else {
      api.clients
        .editGoal({
          userId: client.user_id,
          goalId: client.goal.id,
          nameCustom: nameCustom,
          progressMetricSummaries: trackedMetricsIds,
        })
        .then((res) => {
          dispatch(
            updateClientLocal({
              user_id: client.user_id,
              goal: res.goal,
            }),
          );
          dispatch(
            updateClientInListLocal({
              user_id: client.user_id,
              goal: res.goal,
            }),
          );
        });
    }
  }

  const goalTemplate = goalTemplates.find(
    (t) => t.id === client.goal?.goal_template_id,
  );

  return (
    <Box sx={sx}>
      <Card variant="outlined" sx={{ p: 1, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box>
            {isEditing ? (
              <FormControl fullWidth size="small">
                <InputLabel>Goal type</InputLabel>
                <Select
                  value={goalTemplateId}
                  label="Goal type"
                  onChange={(event) => {
                    setGoalTemplateId(event.target.value);
                  }}
                >
                  {goalTemplates.map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="overline">
                Goal type: {goalTemplate?.name ?? "error (unknown)"}
              </Typography>
            )}
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Client's WHY"
                placeholder="Feel good in a swimsuit"
                multiline
                value={nameCustom}
                onChange={(event) => {
                  setNameCustom(event.target.value);
                }}
                sx={{ mt: 2 }}
              />
            ) : (
              <Typography variant="h6">{client.goal.name_custom}</Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!disableEditing && !isEditing && (
              <IconButton
                size="small"
                onClick={() => {
                  setEditDialogOpen(true);
                }}
                sx={{ ml: 1 }}
              >
                <EditCalendarRoundedIcon fontSize="small" />
              </IconButton>
            )}
            {!disableEditing && (
              <IconButton
                size="small"
                onClick={() => {
                  if (isEditing) {
                    saveGoal();

                    setIsEditing(false);
                  } else {
                    setIsEditing(true);
                  }
                }}
              >
                {isEditing ? (
                  <SaveRoundedIcon fontSize="small" />
                ) : (
                  <EditRoundedIcon fontSize="small" />
                )}
              </IconButton>
            )}
          </Box>
        </Box>
        {isEditing ? (
          <Autocomplete
            multiple
            size="small"
            id="checkboxes-tags-demo"
            options={progressMetrics.map((m) => m.id)}
            disableCloseOnSelect
            getOptionLabel={(option) =>
              progressMetrics.find((m) => m.id === option)?.name ?? ""
            }
            fullWidth
            value={trackedMetricsIds}
            onChange={(event, newValues: any) => {
              setTrackedMetricsIds(newValues);
            }}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {progressMetrics.find((m) => m.id === option)?.name ?? ""}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tracked metrics"
                placeholder="Add a metric"
              />
            )}
            sx={{ my: 2 }}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="overline">
            Created:{" "}
            {format(new Date(client.goal.date_created), "MMM do, yyyy")}
          </Typography>
          {/* <Button
          size="small"
          variant="text"
          onClick={() => {
            setShowProgress(!showProgress);
          }}
          startIcon={
            showProgress ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
          }
        >
          Progress
        </Button> */}
        </Box>
      </Card>
      {showProgress && (
        <Stack direction={"column"} spacing={1} sx={{ py: 1 }}>
          {client.goal.progress_metric_summaries?.map(
            (progressMetricSummary) => {
              if (
                progressMetricSummary.progress_metric_id === "progress_picture"
              ) {
                return null;
              }

              return (
                <GoalCardMetric
                  key={progressMetricSummary.progress_metric_id}
                  userId={client.user_id}
                  progressMetricId={progressMetricSummary.progress_metric_id}
                  preferredWeightSystem={client.preferred_weight_system}
                />
              );
            },
          )}
        </Stack>
      )}
      {editDialogOpen && (
        <GoalEditDialog
          client={client}
          onClose={() => {
            setEditDialogOpen(false);
          }}
          open={editDialogOpen}
        />
      )}
    </Box>
  );
}
