import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { Button, Popover, TextField, Typography } from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TagList } from "src/components/misc/TagList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import { savePhaseAsTemplate } from "src/slices/phaseTemplatesSlice";
import { selectDefaultTags, selectPhaseById } from "src/slices/phasesSlice";
import { selectPossibleTemplateTags } from "src/slices/templatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  phaseId: string;
  anchorEl: Element | null;
  onClose: () => void;
};

export function SaveAsTemplatePopover({ phaseId, anchorEl, onClose }: Props) {
  const dispatch = useAppDispatch();
  const phase = useAppSelector((state) => selectPhaseById(state, phaseId));
  const defaultTags = useAppSelector((state) =>
    selectDefaultTags(state, phaseId),
  );
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const possibleTags = useAppSelector((state) =>
    selectPossibleTemplateTags(state, trainerId),
  );
  const [name, setName] = useState<string>(phase?.name ?? "");
  const [tags, setTags] = useState<string[]>(defaultTags);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (open) {
      setName(phase?.name ?? "");
      setTags(defaultTags);
    }
  }, [open]);

  function handleClose() {
    onClose();

    setLoading(false);
    setName(phase?.name ?? "");
    setTags(defaultTags);
  }

  function handleSave() {
    setLoading(true);

    dispatch(
      savePhaseAsTemplate({
        name: name,
        tags: tags,
        phaseId: phaseId,
      }),
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar("Saved as template", {
          variant: "success",
        });

        handleClose();
      })
      .catch(() => {
        setLoading(false);

        enqueueSnackbar("Error saving as template", {
          variant: "error",
        });
      });
  }

  function addTagHandler(tag: string) {
    const newTags = JSON.parse(JSON.stringify(tags));

    const index = newTags.indexOf(tag);

    if (index === -1) {
      newTags.push(tag);
      setTags(newTags);
    }
  }

  function removeTagHandler(tag: string) {
    const newTags = JSON.parse(JSON.stringify(tags));

    const index = newTags.indexOf(tag);

    if (index !== -1) {
      newTags.splice(index, 1);
      setTags(newTags);
    }
  }

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: { width: 450, p: 2 },
        },
      }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Save as template
      </Typography>
      <TextField
        multiline={true}
        fullWidth={true}
        label="Template name"
        onChange={(event) => {
          setName(event.target.value);
        }}
        value={name}
        sx={{ mb: 1 }}
        size="small"
      ></TextField>
      <TagList
        value={(tags ?? []).map((t) => {
          const tagDetails = getSmartTagDetails(t);

          return {
            id: t,
            icon: tagDetails.icon,
            label: tagDetails.label ?? t,
          };
        })}
        size="small"
        options={possibleTags.map((t) => {
          const tagDetails = getSmartTagDetails(t);

          return {
            id: t,
            icon: tagDetails.icon,
            label: tagDetails.label ?? t,
          };
        })}
        onAdd={addTagHandler}
        onRemove={removeTagHandler}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleSave}
        startIcon={<SaveRoundedIcon />}
        disabled={isBlank(name)}
        loading={loading}
      >
        Save
      </Button>
    </Popover>
  );
}
