import { Box, Paper, Stack, Typography } from "@mui/material";
import { useAudits } from "@trainwell/features/audits";
import { useParams } from "react-router";
import ControlBar from "src/components/misc/ControlBar";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import AuditCell from "./AuditCell";
import AuditEditPage from "./AuditEditPage";

const drawerWidth = 260;

export default function SelfAuditsPage() {
  const { auditId } = useParams();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: audits } = useAudits({
    filter: { clients_trainer_id: trainer!.trainer_id },
  });

  if (audits === undefined) {
    return <LoadingPage />;
  }

  const selectedAudit = audits.find((audit) => audit.id === auditId);

  return (
    <>
      <ControlBar />
      <Box sx={{ display: "flex", height: "100%", mt: "38px" }}>
        <Paper
          square
          elevation={0}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            maxHeight: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRightWidth: "1px",
            borderRightColor: (theme) => theme.palette.divider,
            borderRightStyle: "solid",
            height: "calc( 100vh - 38px )",
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.backgroundSecondary.main,
              p: 2,
            }}
          >
            <Typography variant="h1">Self audits</Typography>
            <Typography variant="overline">
              Showing {audits.length} audits
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", flex: 1 }}>
            <Stack direction="column">
              {audits.map((audit) => (
                <AuditCell
                  key={audit.id}
                  audit={audit}
                  isSelected={selectedAudit?.id === audit.id}
                />
              ))}
            </Stack>
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflowX: "hidden", p: 3 }}>
          {selectedAudit ? (
            <AuditEditPage audit={selectedAudit} />
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>Select an audit to get started</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
