import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { getSentimentEmoji } from "@trainwell/features/nutrition";
import type { MessageMealLog } from "@trainwell/types";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";

type Props = {
  message: MessageMealLog;
  combineBottom: boolean;
};

export function MealLogMessage({ message, combineBottom }: Props) {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `10px 10px 10px ${!combineBottom ? "0px" : "10px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          p: 1,
          display: "flex",
          alignItems: "center",
          minWidth: "200px",
          // border: 1,
          // borderColor: "divider",
        }}
      >
        <Stack
          direction={"column"}
          useFlexGap
          spacing={1}
          flex={1}
          alignItems={"flex-start"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RestaurantRoundedIcon />
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
                ml: 1,
              }}
            >
              Meal logged
            </Typography>
          </Box>
          <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
            <Box>
              <Typography>
                EatWell score:{" "}
                {Math.round(message.content.meal_log.eatwell_score * 100)}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={message.content.meal_log.eatwell_score * 100}
                sx={{
                  borderRadius: 1,
                }}
              />
            </Box>
            <Box>
              <Typography>
                Lightness:{" "}
                {Math.round(
                  (1 - message.content.meal_log.caloric_density_estimate) * 100,
                )}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  (1 - message.content.meal_log.caloric_density_estimate) * 100
                }
                sx={{
                  borderRadius: 1,
                }}
              />
            </Box>
            <Box>
              <Typography>
                Fillingness:{" "}
                {Math.round(message.content.meal_log.satiation_estimate * 100)}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={message.content.meal_log.satiation_estimate * 100}
                sx={{
                  borderRadius: 1,
                }}
              />
            </Box>
            {message.content.meal_log.sentiment_rating && (
              <Box>
                <Typography>
                  Client sentiment:{" "}
                  {Math.round(
                    (message.content.meal_log.sentiment_rating ?? 0) + 1,
                  )}{" "}
                  / 5{" "}
                  {getSentimentEmoji(
                    message.content.meal_log.sentiment_rating ?? 0,
                  )}
                </Typography>

                <LinearProgress
                  variant="determinate"
                  value={(message.content.meal_log.sentiment_rating / 4) * 100}
                  sx={{
                    borderRadius: 1,
                  }}
                />
              </Box>
            )}
          </Stack>
          <img
            height={150}
            style={{
              // width: "100%",
              borderRadius: "10px",
              display: "block",
              objectFit: "contain",
            }}
            src={message.content.meal_log.image_url}
            onClick={() => {
              setImageDialogOpen(true);
            }}
          />
        </Stack>
      </Box>
      <Dialog
        onClose={() => {
          setImageDialogOpen(false);
        }}
        aria-labelledby="client-name"
        open={imageDialogOpen}
      >
        <DialogTitleWithClose
          onClose={() => {
            setImageDialogOpen(false);
          }}
        >
          Image
        </DialogTitleWithClose>
        <DialogContent dividers>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              width={600}
              height={600}
              src={message.content.meal_log.image_url}
              style={{
                objectFit: "contain",
              }}
              alt="chat media"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
