import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { matchPath, useLocation } from "react-router";
import { TagList } from "src/components/misc/TagList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import {
  createPhaseTemplate,
  selectCanEditTrainwellLibrary,
  selectUsedPhaseTemplateTags,
  setPhaseTemplateEditing,
  updatePhaseTemplate,
  updatePhaseTemplateEditing,
} from "src/slices/phaseTemplatesSlice";
import PhaseTemplateBuilderCard from "./PhaseTemplateBuilderCard";

type Props = {
  onClose: () => void;
};

export default function PhaseTemplateBuilder({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const phaseTemplate = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplateEditing,
  );
  const possibleTags = useAppSelector((state) =>
    selectUsedPhaseTemplateTags(state, phaseTemplate?.trainer_id ?? ""),
  );
  const location = useLocation();
  const match = matchPath(
    { path: "/template-library/folders/:phaseTemplateFolderId" },
    location.pathname,
  );
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const canEditTrainwellLibrary = useAppSelector(selectCanEditTrainwellLibrary);

  const disabled =
    !canEditTrainwellLibrary && phaseTemplate?.trainer_id === "copilot";

  if (!phaseTemplate) {
    return null;
  }

  function handleClose() {
    dispatch(setPhaseTemplateEditing(null));
    onClose();
  }

  const isValid =
    !isBlank(phaseTemplate.name) &&
    !isBlank(phaseTemplate.description) &&
    phaseTemplate.days_draggable.every(
      (day) => day.templates.length > 0 || day.draggable_id === "new_day",
    );

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        p: 2,
        pb: 16,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1">
          {phaseTemplate._id === null ? "New" : "Edit"} phase
        </Typography>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          disabled={saving}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <TextField
        label="Name"
        value={phaseTemplate.name}
        onChange={(event) => {
          dispatch(updatePhaseTemplateEditing({ name: event.target.value }));
        }}
        fullWidth
        sx={{ mb: 2 }}
        disabled={disabled}
      />
      <TextField
        label="Description"
        value={phaseTemplate.description}
        onChange={(event) => {
          dispatch(
            updatePhaseTemplateEditing({ description: event.target.value }),
          );
        }}
        fullWidth
        multiline
        minRows={2}
        sx={{ mb: 2 }}
        disabled={disabled}
      />
      <TagList
        value={(phaseTemplate.tags ?? [])
          .map((t) => getSmartTagDetails(t).label)
          .map((t) => ({ id: t, label: t }))}
        options={possibleTags
          .filter((t) => !t.startsWith("smart:"))
          .map((t) => ({ id: t, label: t }))}
        disabled={disabled}
        onAdd={(tag) => {
          dispatch(
            updatePhaseTemplateEditing({
              tags: [...new Set([...phaseTemplate.tags, tag])],
            }),
          );
        }}
        onRemove={(tag) => {
          dispatch(
            updatePhaseTemplateEditing({
              tags: phaseTemplate.tags.filter((t) => t !== tag),
            }),
          );
        }}
        sx={{ mb: 2 }}
      />
      <PhaseTemplateBuilderCard
        days={phaseTemplate.days_draggable}
        disabled={disabled}
      />
      {!disabled && (
        <Button
          variant="contained"
          fullWidth
          disabled={!isValid}
          startIcon={<SaveRoundedIcon />}
          onClick={() => {
            setSaving(true);

            if (phaseTemplate._id === null) {
              dispatch(
                createPhaseTemplate({
                  parentFolderId: match?.params.phaseTemplateFolderId,
                }),
              )
                .unwrap()
                .then(() => {
                  enqueueSnackbar("Created", { variant: "success" });

                  setSaving(false);
                  handleClose();
                })
                .catch(() => {
                  enqueueSnackbar("Error", { variant: "error" });

                  setSaving(false);
                });
            } else {
              dispatch(
                updatePhaseTemplate({
                  id: phaseTemplate._id,
                  daysDraggable: phaseTemplate.days_draggable,
                  description: phaseTemplate.description,
                  tags: phaseTemplate.tags,
                  name: phaseTemplate.name,
                }),
              )
                .unwrap()
                .then(() => {
                  enqueueSnackbar("Saved", { variant: "success" });

                  setSaving(false);
                  handleClose();
                })
                .catch(() => {
                  enqueueSnackbar("Error", { variant: "error" });

                  setSaving(false);
                });
            }
          }}
          loading={saving}
        >
          Save
        </Button>
      )}
    </Box>
  );
}
