import type { Theme } from "@mui/material";
import type { Client } from "@trainwell/types";
import { differenceInCalendarDays, format, isFuture } from "date-fns";

export function getClientStatusColor(client: Client, theme: Theme): string {
  if (client.account.plan.price === 0 && client.account.plan.brand !== "gf") {
    return theme.palette.success.main;
  } else if (
    client.account.membership.is_expiring === true ||
    client.account.membership.state === "past_due"
  ) {
    return theme.palette.error.main;
  } else {
    return theme.palette.text.primary;
  }
}

export function getClientSummary(account: Client["account"]): {
  text: string;
  isSpecial: boolean;
} {
  const statuses: string[] = [];
  let isSpecial = false;

  if (account.membership.state === "active") {
    // statuses.push("Active");
  } else if (account.membership.state === "paused") {
    statuses.push("Paused");
  } else if (account.membership.state === "cancelled") {
    statuses.push("Cancelled");
  } else if (account.membership.state === "past_due") {
    statuses.push("Past due");

    isSpecial = true;
  }

  if (account.membership.state !== "cancelled") {
    if (!account.dashboard.date_onboarded) {
      statuses.push("Trial not started");
    } else if (
      account.plan.date_trial_end &&
      isFuture(new Date(account.plan.date_trial_end))
    ) {
      statuses.push(
        "In trial: " +
          differenceInCalendarDays(
            new Date(account.plan.date_trial_end),
            new Date(),
          ) +
          " days left",
      );
      isSpecial = true;
    } else {
      if (account.plan.brand === "gf") {
        statuses.push("Paid through partner");
      } else if (account.plan.price === 0 || !account.plan.price) {
        statuses.push("Free");
        isSpecial = true;
      } else {
        if (
          account.plan.interval === "month" &&
          account.plan.interval_count === 1
        ) {
          statuses.push("Monthly");
        } else if (
          account.plan.interval === "year" &&
          account.plan.interval_count === 1
        ) {
          statuses.push("Yearly");
        } else {
          statuses.push(
            `${account.plan.interval_count} ${account.plan.interval}s`,
          );
        }

        if (account.card.state === "invalid_card") {
          statuses.push("Invalid card");
          isSpecial = true;
        } else if (account.card.state === "no_card") {
          statuses.push("No card");
          isSpecial = true;
        }
      }
    }
  }

  if (
    account.membership.state === "cancelled" &&
    !account.dashboard.date_onboarded
  ) {
    statuses.push("Trial never started");
  }

  if (account.membership.date_will_pause) {
    statuses.push(
      `Will pause on ${format(
        new Date(account.membership.date_will_pause),
        "MMM do",
      )}`,
    );
    isSpecial = true;
  }
  if (account.membership.date_pause_will_end) {
    statuses.push(
      `Pause ends ${format(
        new Date(account.membership.date_pause_will_end),
        "MMM do",
      )}`,
    );
    isSpecial = true;
  } else if (account.membership.is_expiring) {
    if (account.membership.date_will_expire) {
      statuses.push(
        `Expiring on ${format(
          new Date(account.membership.date_will_expire),
          "MMM do",
        )}`,
      );
      isSpecial = true;
    } else {
      statuses.push("Expiring");
      isSpecial = true;
    }
  }

  if (account.membership.date_deleted) {
    statuses.push("Deleted");
    isSpecial = true;
  } else if (account.membership.date_requested_deletion) {
    statuses.push("Delete requested");
    isSpecial = true;
  }

  return { text: statuses.join(". "), isSpecial: isSpecial };
}

export function getCardState(
  state: Client["account"]["card"]["state"],
): string {
  switch (state) {
    case "invalid_card":
      return "Invalid";
    case "no_card":
      return "No card";
    case "valid_card":
      return "Valid";
  }
}

export function getBrand(state: Client["account"]["plan"]["brand"]): string {
  switch (state) {
    case "af":
      return "Anytime Fitness";
    case "copilot":
      return "trainwell";
    case "echelon":
      return "Echelon";
    case "gf":
      return "GlobalFit / Husk";
  }
}

function sortClientsByTrial(a: Client, b: Client) {
  // 0, 1, 2 = "pre_trial" | "trial" | "post_trial"
  let aState = 2;

  if (!a.account.dashboard.date_onboarded) {
    aState = 0;
  } else if (
    a.account.plan.date_trial_end &&
    isFuture(new Date(a.account.plan.date_trial_end))
  ) {
    aState = 1;
  }

  let bState = 2;

  if (!b.account.dashboard.date_onboarded) {
    bState = 0;
  } else if (
    b.account.plan.date_trial_end &&
    isFuture(new Date(b.account.plan.date_trial_end))
  ) {
    bState = 1;
  }

  return aState - bState;
}

export function sortClientsByName(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  return (
    a.full_name.localeCompare(b.full_name) * (direction === "asc" ? 1 : -1)
  );
}

export function sortClientsByTenureCoach(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const dateA =
    a.date_latest_coach_switch ?? a.account.membership.date_account_created;
  const dateB =
    b.date_latest_coach_switch ?? b.account.membership.date_account_created;

  return (
    (dateA as string).localeCompare(dateB as string) *
    (direction === "asc" ? 1 : -1)
  );
}

export function sortClientsByTenureTrainwell(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  return (
    (a.account.membership.date_account_created as string).localeCompare(
      b.account.membership.date_account_created as string,
    ) * (direction === "asc" ? 1 : -1)
  );
}

export function sortClientsByLastWorkoutDate(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const aHasLastWorkout =
    "last_workout_date" in a && a.last_workout_date !== null;
  const bHasLastWorkout =
    "last_workout_date" in b && b.last_workout_date !== null;

  if (aHasLastWorkout && !bHasLastWorkout) {
    return direction === "asc" ? -1 : 1;
  } else if (!aHasLastWorkout && bHasLastWorkout) {
    return direction === "asc" ? 1 : -1;
  } else if (!aHasLastWorkout || !bHasLastWorkout) {
    return 0;
  }

  return (
    -(a.last_workout_date! as string).localeCompare(
      b.last_workout_date! as string,
    ) * (direction === "asc" ? 1 : -1)
  );
}

export function sortClientsByLastMessageDate(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const aHas =
    "last_trainer_message_date" in a && a.last_trainer_message_date !== null;
  const bHas =
    "last_trainer_message_date" in b && b.last_trainer_message_date !== null;

  if (aHas && !bHas) {
    return direction === "asc" ? 1 : -1;
  } else if (!aHas && bHas) {
    return direction === "asc" ? -1 : 1;
  } else if (!aHas && !bHas) {
    return 0;
  }

  return (
    (a.last_trainer_message_date! as string).localeCompare(
      b.last_trainer_message_date! as string,
    ) * (direction === "asc" ? 1 : -1)
  );
}

export function sortClientsByWeeks(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
  clientInfo: Record<string, { weeks: number }>,
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const valA = clientInfo[a.user_id]?.weeks ?? 0;
  const valB = clientInfo[b.user_id]?.weeks ?? 0;

  if (valA > valB) {
    return direction === "asc" ? -1 : 1;
  } else if (valA === valB) {
    return 0;
  } else if (valA < valB) {
    return direction === "asc" ? 1 : -1;
  }
  return 0;
}

export function sortClientsByNextWorkout(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
  clientInfo: Record<string, { daysUntilNextWorkout: number | null }>,
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const valA = clientInfo[a.user_id]?.daysUntilNextWorkout ?? 0;
  const valB = clientInfo[b.user_id]?.daysUntilNextWorkout ?? 0;

  if (valA > valB) {
    return direction === "asc" ? -1 : 1;
  } else if (valA === valB) {
    return 0;
  } else if (valA < valB) {
    return direction === "asc" ? 1 : -1;
  }
  return 0;
}

export function sortClientsByMissedDays(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const valA = a.missed_workout_day_streak ?? 0;
  const valB = b.missed_workout_day_streak ?? 0;

  if (valA > valB) {
    return direction === "asc" ? -1 : 1;
  } else if (valA === valB) {
    return 0;
  } else if (valA < valB) {
    return direction === "asc" ? 1 : -1;
  }
  return 0;
}

export function sortClientsByWorkoutCompletion(
  a: Client,
  b: Client,
  direction: "asc" | "desc",
) {
  const trialSort = sortClientsByTrial(a, b);

  if (trialSort !== 0) {
    return trialSort;
  }

  const valA = a.metrics?.workout_completion_week ?? 0;
  const valB = b.metrics?.workout_completion_week ?? 0;

  if (valA > valB) {
    return direction === "asc" ? -1 : 1;
  } else if (valA === valB) {
    return 0;
  } else if (valA < valB) {
    return direction === "asc" ? 1 : -1;
  }
  return 0;
}

export function getClientDisplayName(client: Client) {
  const terms = [client.full_name];

  if (client.is_vip) {
    terms.unshift("👑");
  }

  return terms.join(" ");
}
