import { fromUnixTime, getUnixTime } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export function getDateForDatabase(date: string | Date) {
  const myTzOffset = new Date().getTimezoneOffset() * -60;

  const startTime = getUnixTime(new Date(date)) + myTzOffset;

  return fromUnixTime(startTime).toISOString();
}

export function getZeroedOutUtcDate(date: string | Date) {
  const newDate = new Date(date);

  newDate.setUTCHours(0, 0, 0, 0);

  return newDate.toISOString();
}

export function getDateFromDatabase(date: string | Date) {
  return toZonedTime(date, "Etc/UTC");
}

export function getLocalDate(utcDate: string | Date | number) {
  let newDate = new Date(utcDate);

  const minutes = newDate.getTimezoneOffset();

  newDate = new Date(newDate.getTime() + minutes * 60000);

  return newDate;
}

export function getCurrentWeekStartDate() {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay());
  date.setHours(0, 0, 0, 0);
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
    ),
  );
}

export function getDifferenceBetweenTimezones(
  date: string | Date,
  timezone1: string,
  timezone2: string,
) {
  const date1 = toZonedTime(date, timezone1);
  const date2 = toZonedTime(date, timezone2);

  return date1.getTime() - date2.getTime();
}

export function formatSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours) {
    return `${hours}h ${minutes}m`;
  } else if (minutes) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
}
