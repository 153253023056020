import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { isBlank } from "@trainwell/features";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  createPhase,
  setPhaseEditing,
  updatePhase,
  updatePhaseEditing,
} from "src/slices/phasesSlice";
import PhaseDaysCard from "./PhaseDaysCard";

type Props = {
  onClose: () => void;
};

export default function PhaseBuilder({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const phase = useAppSelector((state) => state.phases.phaseEditing);
  const userId = useAppSelector((state) => state.client.client!.user_id);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const dndScrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = dndScrollableRef.current;
    if (!element) {
      return;
    }

    return autoScrollForElements({
      element,
    });
  });

  if (!phase) {
    return null;
  }

  function handleClose() {
    dispatch(setPhaseEditing(null));
    onClose();
  }

  const isValid =
    !isBlank(phase.name) &&
    phase.days_draggable.every(
      (day) => day.workouts.length > 0 || day.draggable_id === "new_day",
    );

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        pb: 16,
        overflowY: "auto",
        height: "100%",
      }}
      ref={dndScrollableRef}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1">
          {phase._id === null ? "New" : "Edit"} phase
        </Typography>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          disabled={saving}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <TextField
        label="Name"
        value={phase.name}
        onChange={(event) => {
          dispatch(updatePhaseEditing({ name: event.target.value }));
        }}
        fullWidth
        multiline
        sx={{ mb: 2 }}
      />
      <TextField
        label="Description"
        size="small"
        helperText="Optional (trainer only)"
        value={phase.description}
        onChange={(event) => {
          dispatch(updatePhaseEditing({ description: event.target.value }));
        }}
        fullWidth
        multiline
        sx={{ mb: 2 }}
      />
      <PhaseDaysCard days={phase.days_draggable} />
      <Button
        variant="contained"
        fullWidth
        disabled={!isValid}
        startIcon={<SaveRoundedIcon />}
        onClick={() => {
          setSaving(true);

          if (phase._id === null) {
            dispatch(
              createPhase({
                userId,
              }),
            )
              .unwrap()
              .then(() => {
                enqueueSnackbar("Created", { variant: "success" });

                setSaving(false);
                handleClose();
              })
              .catch(() => {
                enqueueSnackbar("Error", { variant: "error" });

                setSaving(false);
              });
          } else {
            dispatch(
              updatePhase({
                id: phase._id,
                daysDraggable: phase.days_draggable,
                description: phase.description,
                tags: phase.tags,
                name: phase.name,
              }),
            )
              .unwrap()
              .then(() => {
                enqueueSnackbar("Saved", { variant: "success" });

                setSaving(false);
                handleClose();
              })
              .catch(() => {
                enqueueSnackbar("Error", { variant: "error" });

                setSaving(false);
              });
          }
        }}
        loading={saving}
      >
        Save
      </Button>
    </Box>
  );
}
