import { useTests } from "@trainwell/features/tests";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function useIsInNutritionV2Test() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: tests } = useTests();

  const nutritionV2Test = tests?.find((test) =>
    test.variants.some((v) => v.id === "nov_nutrition_treatment"),
  );

  return (
    nutritionV2Test?.is_active &&
    nutritionV2Test.supported_trainer_ids?.includes(trainer?.trainer_id ?? "")
  );
}
