import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  type TrainerMedia,
  useCreateTrainerMedia,
  useDeleteTrainerMedia,
  useTrainerMedias,
  useUpdateTrainerMedia,
} from "@trainwell/features/trainer-media";
import Uppy from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import ScreenCapture from "@uppy/screen-capture";
import Transloadit from "@uppy/transloadit";
import Webcam from "@uppy/webcam";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useMemo, useRef, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { TRANSLOADIT_AUTH_KEY } from "src/config/config";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  open: boolean;
  onClose: (coachMedia?: TrainerMedia) => void;
};

export function MessageLibraryDialog({ open, onClose }: Props) {
  const theme = useTheme();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { data: trainerMedias, isPending } = useTrainerMedias({
    filter: {
      trainer_id: trainer!.trainer_id,
    },
    queryConfig: {
      enabled: Boolean(trainer) && open,
    },
  });
  const addCoachMedia = useCreateTrainerMedia();

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchRef = useRef<HTMLDivElement | null>(null);

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 10,
        allowedFileTypes: ["video/*"],
      },
    })
      .use(Webcam, {
        modes: ["video-audio"],
        videoConstraints: {
          facingMode: "user",
        },
        showRecordingLength: true,
        mobileNativeCamera: true,
      })
      .use(ScreenCapture)
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: { key: TRANSLOADIT_AUTH_KEY },
            template_id: "coach-media",
          },
        },
        waitForEncoding: true,
      }),
  );

  useUppyEvent(uppy, "transloadit:complete", (assemblyResponse) => {
    console.log("Uppy: upload success", assemblyResponse);

    if (
      "videos" in assemblyResponse.results &&
      assemblyResponse.results.videos.length &&
      "thumbnails" in assemblyResponse.results &&
      assemblyResponse.results.thumbnails.length
    ) {
      for (const videoResult of assemblyResponse.results.videos) {
        const thumbnailResult = assemblyResponse.results.thumbnails.find(
          (t) => t.original_id === videoResult.original_id,
        );

        if (videoResult.ssl_url && thumbnailResult?.ssl_url) {
          addCoachMedia.mutate({
            data: {
              video_url: videoResult.ssl_url,
              thumbnail_url: thumbnailResult.ssl_url,
              height: videoResult.meta.height,
              width: videoResult.meta.width,
              trainer_id: trainer!.trainer_id,
              name: "New video",
            },
          });
        }
      }
    }
  });

  useUppyEvent(uppy, "complete", () => {
    console.log("Uppy: upload complete");

    uppy.cancelAll();
    setUploadDialogOpen(false);
  });

  const displayTrainerMedias = useMemo(() => {
    const sortedMedia = (trainerMedias ?? []).sort((a, b) => {
      if (a.favorite && !b.favorite) {
        return -1;
      } else if (!a.favorite && b.favorite) {
        return 1;
      } else {
        return (b.date_created as string).localeCompare(
          a.date_created as string,
        );
      }
    });

    if (!searchText) {
      return sortedMedia;
    } else {
      return sortedMedia.filter((m) =>
        m.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    }
  }, [searchText, trainerMedias]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        searchRef.current?.focus();
      }, 50);
    } else {
      setSearchText("");
    }
  }, [open]);

  return (
    <>
      <Dialog
        onClose={(event) => {
          // @ts-expect-error
          event.stopPropagation();

          onClose();
        }}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitleWithClose
          onClose={(event) => {
            event.stopPropagation();

            onClose();
          }}
        >
          Message library
        </DialogTitleWithClose>
        <DialogContent
          sx={{
            pb: 0,
          }}
        >
          <Typography sx={{ mb: 2, maxWidth: 500 }}>
            Media you upload here is higher quality and can be sent instantly
            (no waiting for an upload).
            <br />
            <br />
            It&apos;s a great spot to keep frequently used, generic videos
            (form, app demos, etc).
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SearchField
              ref={searchRef}
              autoFocus
              value={searchText}
              onChange={(value) => {
                setSearchText(value);
              }}
              onClear={() => {
                setSearchText("");
              }}
            />
            <Button
              startIcon={<UploadRoundedIcon />}
              onClick={() => {
                setUploadDialogOpen(true);
              }}
              sx={{ ml: 2, minWidth: "180px" }}
            >
              Upload media
            </Button>
          </Box>
          <Box sx={{ height: "600px", overflowY: "auto", py: 4 }}>
            {isPending ? (
              <LoadingComponent message="Loading media" />
            ) : displayTrainerMedias.length === 0 ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {searchText ? (
                  <SearchRoundedIcon
                    sx={{
                      fontSize: 52,
                      mb: 2,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                ) : (
                  <InboxRoundedIcon
                    sx={{
                      fontSize: 52,
                      mb: 2,
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                )}
                <Typography
                  sx={{
                    textAlign: "center",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  No {searchText ? "media matched search" : "media"}
                </Typography>
              </Box>
            ) : (
              <Stack spacing={2} direction={"column"}>
                {displayTrainerMedias.map((trainerMedia) => (
                  <MediaCell
                    key={trainerMedia.id}
                    trainerMedia={trainerMedia}
                    onSend={(trainerMediaToSend) => {
                      onClose(trainerMediaToSend);
                    }}
                  />
                ))}
              </Stack>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={(event) => {
          // @ts-expect-error
          event.stopPropagation();

          setUploadDialogOpen(false);
        }}
        open={uploadDialogOpen}
      >
        <DialogTitleWithClose
          onClose={(event) => {
            event.stopPropagation();

            setUploadDialogOpen(false);
          }}
        >
          Upload media
        </DialogTitleWithClose>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box>
            <Dashboard
              uppy={uppy}
              plugins={["Webcam", "ScreenCapture"]}
              proudlyDisplayPoweredByUppy={false}
              showLinkToFileUploadResult={false}
              height={"500px"}
              doneButtonHandler={() => {
                setUploadDialogOpen(false);
              }}
              theme={theme.palette.mode}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

type MediaCellProps = {
  trainerMedia: TrainerMedia;
  onSend: (trainerMedia: TrainerMedia) => void;
};

function MediaCell({ trainerMedia, onSend }: MediaCellProps) {
  const updateTrainerMedia = useUpdateTrainerMedia();
  const deleteCoachMedia = useDeleteTrainerMedia();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const notesOpen = Boolean(anchorEl);
  const confirm = useConfirm();

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size="auto">
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                updateTrainerMedia.mutate({
                  trainerMediaId: trainerMedia.id,
                  data: {
                    favorite: !trainerMedia.favorite,
                  },
                });
              }}
            >
              {trainerMedia.favorite ? (
                <StarRoundedIcon />
              ) : (
                <StarOutlineRoundedIcon />
              )}
            </IconButton>
          </Box>
        </Grid2>
        <Grid2 size={3}>
          <video
            controls
            width={"100%"}
            style={{
              borderRadius: "8px",
              display: "block",
              width: "100%",
            }}
            poster={trainerMedia.thumbnail_url}
          >
            <source src={trainerMedia.video_url} />
          </video>
        </Grid2>
        <Grid2
          size={"grow"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="text"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            sx={{
              textAlign: "start",
              mb: 1,
            }}
            endIcon={<EditRoundedIcon />}
          >
            {trainerMedia.name === "New video" ? "Add name" : trainerMedia.name}
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 0.5,
            }}
          >
            <Button
              startIcon={<SendRoundedIcon />}
              onClick={() => {
                onSend(trainerMedia);
              }}
              sx={{
                minWidth: 150,
              }}
            >
              Send
            </Button>
            <IconButton
              color="error"
              onClick={() => {
                confirm({
                  title: "Delete media",
                  description: "Are you sure you want to delete this media?",
                  confirmationText: "Delete",
                  confirmationButtonProps: {
                    color: "error",
                    autoFocus: true,
                  },
                  cancellationText: "Cancel",
                }).then(() => {
                  deleteCoachMedia.mutate(trainerMedia.id);
                });
              }}
              sx={{ ml: 2 }}
            >
              <DeleteRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid2>
      </Grid2>
      <Popover
        open={notesOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1 }}>
          <TextField
            size="small"
            autoFocus={true}
            defaultValue={trainerMedia.name ?? "New video"}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                // @ts-expect-error
                event.target.blur();
              }
            }}
            onBlur={(event) => {
              updateTrainerMedia.mutate({
                trainerMediaId: trainerMedia.id,
                data: {
                  name: event.target.value ?? "New video",
                },
              });

              setAnchorEl(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
}
